@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

:root {
  --accent: #EF6F53;
  --lightBlue: #285A84;
  --darkBlue: #191847;
  --c-secondary: rgb(233, 155, 53);
  --c-danger: #EE817E;
  --c-bg: #F5F5F5;
  --c-txt: #555555;
  --c-txt-secondary: #888888;
  --bg-nav: #525252;
  --c-nav: #ffffff;
  --c-yellow: #EBA844;
  --c-blue: #5990DC;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  overflow: auto;
}

.error-msg {
  font-size: 14px;
  color: #e0231d;
}

header {
  background: #FFFFFF;
  box-shadow: 0 4px 4px #C5CFD6;
  height: 80px;
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 999;
  .header {
    margin-left: 60px;
    margin-top: 20px;
    img {
      width: 190px;
    }
  }
}

.content-wrap {
  margin-top: 80px;
  color: var(--darkBlue)
}

.home-wrapper {
  background-color: white;
}

main {
  height: 100%;
  //display: grid;
  //grid-template-columns: minmax(60px, 1fr) repeat(8, minmax(50px, 190px)) minmax(60px, 1fr);
  font-family: "Nunito Sans", sans-serif;
  width: 100%;
  scroll-behavior: smooth;
}

.mobile-menu {
  display: none;
}

.section-benefits {
  display: flex;
  flex-direction: column;
  color: var(--darkBlue);
  .title {
    text-align: center;
    font-weight: bold;
    font-size: 44px;
    line-height: 60px;
    color: var(--lightBlue);
    padding: 2.8125rem;
  }
  .section-content {
    display: flex;
    background-image: url('../assets/images/bkg-benefits.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    .second {
      margin-top: -5rem !important;
    }
    .benefit-wrap:first-child {
      margin-right: 2rem;
    }
    .benefit-wrap {
      box-shadow: 0 4px 15px rgba(40, 90, 132, 0.2);
      background: #FFFFFF;
      flex: 1;
      margin: 3rem 3rem -4rem;
      img {
        margin-top: -3rem;
      }
      .benefit-text {
        padding: 3.5rem;
        h2 {
          color: var(--accent);
          font-size: 20px;
          line-height: 27px;
          text-transform: uppercase;
        }
        h1{
          font-weight: bold;
          font-size: 36px;
          line-height: 49px;
          color: var(--darkBlue);
          padding: 2rem;
        }
        p{
          padding: 0 2rem;
        }
      }
    }
  }
}
.section-steps {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  align-content: center;
  text-align: center;
  color: var(--darkBlue);
  .title {
    text-align: center;
    font-weight: bold;
    font-size: 44px;
    line-height: 60px;
    color: var(--lightBlue);
    padding: 2.5rem 2.5rem 0;
    margin-top: 3rem;
  }
  .section-content {
    display: flex;
    padding: 2.5rem 4rem;
    .step {
      padding: 1.5625rem;
      .first-img {
        margin-top: -1rem;
      }
      .step-text {
        padding-top: 1rem;
        p {
          padding-top: 1rem;
          color: var(--darkBlue);
        }
      }
    }
  }
}

.section-login {
  display: flex;
  flex-direction: column;
  background-image: url('../assets/images/background-sec-home.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
  .header {
    height: 160px;
    img {
      margin-top: 54px;
      margin-left: 66px;
    }
  }
  .section-content {
    display: flex;
    flex-direction: row;
    img {
      flex: 1;
      margin: 0 3rem 3rem 6rem;
      width: 750px;
      height: 800px;
    }
    .form-margintop {
       margin-top: 4rem;
     }
    .registration-form {
      background: #FFFFFF;
      box-shadow: 0 4px 10px rgba(40, 90, 132, 0.2);
      line-height: 33px;
      color: var(--darkBlue);
      padding: 30px;
      width: 450px;
      align-content: center;
      height: fit-content;
      margin-left: 8rem;
      margin-right: 10rem;
      p {
        padding-bottom: 20px;
        font-weight: bold;
        font-size: 30px;
      }
      .radio-input {
        padding: 5px;
        font-size: 1.375rem;
        line-height: 33px;
        input {
          cursor: pointer;
        }
        label {
          padding: 0 20px;
          font-weight: 600;
        }
      }
      .chb-input {
        padding: 5px 5px 15px;
        vertical-align: center;
        label {
          padding: 0 20px;
          font-size: 0.875rem;
        }
        a {
          color: var(--lightBlue);
        }
      }
      .text-input {
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        line-height: 22px;
        input {
          line-height: 33px;
          border-radius: 2px;
          border: 1px solid rgba(40, 90, 132, 0.61);
          padding: 0 10px;
          height: 40px;
        }

        label {
          font-size: 1rem;
        }
      }
      .submit-btn {
        border: none;
        background-color: var(--lightBlue);
        text-transform: uppercase;
        text-align: center;
        width: 100%;
        border-radius: 2px;
        line-height: 42px;
        color: #FFFFFF;
        margin-bottom: 1rem;
        margin-top: 1rem;
        cursor: pointer;
      }
      .signin-txt {
        font-family: 'Nunito Sans', sans-serif;
        font-size: 16px;
        line-height: 22px;
        margin-top: 1rem;
        text-align: center;
        align-self: center;
        align-items: center;
      }
      .sign-in {
        color: var(--lightBlue);
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
.profile-header {
  display: flex;
  flex-direction: row;
  .header {
    margin-top: 1rem;
    width: 110%;
  }
  .dropdown-menu-header {
    width: 40%;
    float: right;
    display: flex;
    flex-direction: row;
    margin-top: 1.5rem;
    .dropdown-wrap {
      text-align: start;
      align-items: flex-start;
      flex: 1;
      width: 250px;
      .img-wrap {
        float: right;
        margin-right: 2rem;
        display: flex;
        flex-direction: row;
        img {
          height: 30px;
          width: 30px;
          border: 1px solid var(--lightBlue);
          border-radius: 15px;
          cursor: pointer;
        }
        .icon {
          cursor: pointer;
          margin-top: 0.5rem;
          margin-left: 0.5rem;
        }
      }
      .dropdown-menu-custom {
        background: var(--lightBlue);
        color: white;
        padding: 0.5rem;
        border-radius: 2px;
        position: absolute;
        margin-top: 2.5rem;
        display: initial;
        .dropdown-item {
          svg {
            padding-top: 5px;
            margin-right: 10px;
            path {
              fill: white;
            }
          }
        }
        button {
          outline: none;
          text-transform: initial;
          text-align: start;
          margin: 0;
          padding-bottom: 10px;
        }
        button:focus {
          background: white;
          outline: none;
        }
        button:hover {
          background: rgba(239, 233, 219, 0.09);
        }
      }
      .show {
        display: none;
      }
    }
    button {
      border: none;
      background-color: var(--lightBlue);
      text-transform: uppercase;
      text-align: center;
      width: 200px;
      border-radius: 2px;
      color: #FFFFFF;
      height: 35px;
      margin-right: 3rem;
    }
  }
}

.container-filter {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(25,24,71,.9);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  .container1 {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    background-color: white;
    padding: 30px;
    border-radius: 2px;
    width: 400px;
    overflow-y: scroll;
    max-height: 85%;
    display: flex;
    flex-direction: column;
    height: 50%;
    .section-wrapper {
      display: flex;
      flex-direction: row;
      h5 {
        flex: 1;
      }
    }
    span {
      margin-top: 1rem;
    }
    .btn-filter {
      border: 1px solid #191847;
      box-sizing: border-box;
      border-radius: 2px;
      width: 100%;
      text-align: center;
      padding: .5rem;
    }
    .dropdown-input {
      position: relative;
      cursor: pointer;
      margin-bottom: .5rem;
      margin-top: .5rem;
      height: 40px;
      .dropdown-wrap {
        text-align: start;
        align-items: flex-start;
        flex: 1;
        flex-direction: row;
        display: flex;
        .icon-arrow-down {
          margin-left: -1.5rem;
          margin-top: 0.6rem;
        }
        input {
          width: 100%;
          cursor: pointer;
          outline: none;
          padding: 5px;
        }
      }
      .dropdown-menu-custom {
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        float: left;
        min-width: 10rem;
        padding: .5rem 0;
        margin: .125rem 0 0;
        font-size: 1rem;
        color: #212529;
        text-align: left;
        list-style: none;
        background-color: #fff;
        background-clip: padding-box;
        border-radius: 2px;
        border: 1px solid rgba(40, 90, 132, 0.61);
        height: 200px;
        overflow: scroll;
        button:focus {
          background: white;
          outline: none;
        }
        button:hover {
          background: rgba(239, 111, 83, 0.2);
        }
      }
      input {
        width: 100%;
      }
    }
    .skills {
      width: 350px;
      margin-bottom: .8rem;
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
.overlay {
  background: rgba(40, 90, 132, 0.3);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.profile-wrap {
  display: flex;
  .side-menu {
    display: flex;
    flex-direction: column;
    width: 30%;
    text-align: center;
    .profile-img {
      background-image: url('../assets/images/profile-img-bkg.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: top;
      img {
        margin-top: 5rem;
        width: 100px;
        height: 100px;
        border-radius: 50px;
        object-fit: cover;
      }
      p{
        margin-top: 3rem;
        font-weight: bold;
        font-size: 20px;
        line-height: 27px;
        text-transform: uppercase;
      }
    }
    .menu-wrap {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-left: 3rem;
      padding: 0 2rem 2rem 4rem;
      margin-top: 1rem;
      .menu-item {
        font-family: 'Nunito Sans', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 30px;
        display: flex;
        flex-direction: row;
        padding: 0.7rem;
        svg {
          margin-right: 1.5rem;
          width: 30px;
          height: 30px;
          cursor: pointer;
        }
        svg:hover {
          path {
            fill: var(--accent);
          }
        }
        label {
          cursor: pointer;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: var(--darkBlue);
        }
      }
      .menu-item:hover {
        label {
          color: var(--accent);
        }
        .color-change {
          fill: var(--accent);
        }
      }
      .active {
        label {
          color: var(--accent);
        }
        .color-change {
          fill: var(--accent);
        }
      }
    }
  }
  .profile-content {
    display: flex;
    width: 70%;
    padding: 1rem;
    margin-right: 2rem;
    main {
      margin-left: -3.3rem;
    }
    .section-wrap {
      display: flex;
      flex-direction: row;
      .smaller-section:first-child {
        margin-right: 1rem;
      }
      .smaller-section {
        width: 50%;
      }
    }
    .divider {
      margin-bottom: 2rem!important;
    }
    .info-section {
      background: #FFFFFF;
      border: 1px solid rgba(40, 90, 132, 0.61);
      box-shadow: 0 4px 15px rgba(40, 90, 132, 0.2);
      border-radius: 2px;
      padding: 2rem;
      margin: 1rem;
      display: flex;
      flex-direction: column;
      .section-title {
        margin-bottom: 2rem;
        display: flex;
        flex-direction: row;
        width: 100%;
        h6 {
          font-weight: 800;
          font-size: 18px;
          line-height: 30px;
          flex: 1;
        }
        .icon {
          margin-top: .5rem;
          color: var(--lightBlue);
          cursor: pointer;
        }
      }
      h5 {
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: var(--accent);
        text-transform: uppercase;
        margin-bottom: 1rem;
      }
      .info-item {
        display: flex;
        flex-direction: row;
        label {
          width: 20%;
          margin: 3px 3px 3px 0;
          padding: 5px 5px 5px 0;
        }
        p {
          width: 80%;
          padding: 7px 7px 7px 0;
          margin: 2px 3px 3px 0;
          word-wrap: break-word;
        }
        input {
          padding: 5px;
          margin: 3px;
          width: 70%;
        }
        textarea {
          padding: 2px 5px;
          margin: 3px;
          width: 70%;
          height: 84px;
        }
        .text-input {
          input {
            width: 100%;
          }
        }
        .dropdown-input {
          position: relative;
          cursor: pointer;
          width: 71%;
          .dropdown-wrap {
            text-align: start;
            align-items: flex-start;
            flex: 1;
            flex-direction: row;
            display: flex;
            .icon-arrow-down {
              margin-left: -1.5rem;
              margin-top: 0.8rem;
            }
            input {
              width: 98.5%;
              cursor: pointer;
              outline: none;
            }
          }
          .dropdown-menu-custom {
            width: 98.5%;
            position: absolute;
            left: 0;
            z-index: 1000;
            float: left;
            min-width: 10rem;
            padding: .5rem 0;
            margin: .130rem 0 0 3px;
            font-size: 1rem;
            color: #212529;
            text-align: left;
            list-style: none;
            background-color: #fff;
            background-clip: padding-box;
            border-radius: 2px;
            border: 1px solid rgba(40, 90, 132, 0.61);
            .dropdown-item {
              svg {
                padding-top: 5px;
                margin-right: 10px;
                path {
                  fill: white;
                }
              }
            }
            button:focus {
              background: white;
              outline: none;
            }
            button:hover {
              background: rgba(239, 111, 83, 0.2);
            }
          }

          datalist {
            width: 100%;
          }
        }
      }
      .editing-layout {
        display: flex;
        flex-direction: column;
        width: 70%;
        .skills {
          width: 100%;
          margin-left: 3px;
        }
        .skills-dropdown {
          height: 300px;
          overflow: scroll;
        }
        .dropdown-input, .text-input, .dropdown-wrap {
          width: 100%;
        }
      }
      .add-wrapper {
        margin-top: 2rem;
        color: var(--darkBlue);
        .icon-plus {
          margin-right: 1rem;
        }
      }
    }
    .section-above {
      height: 6.6rem;
      margin-right: 1.5rem;
      .share {
        //flex: 1;
        float: right;
        font-size: 16px;
        cursor: pointer;
        img {
          margin-bottom: 5px;
          margin-right: 1rem;
        }
        label {
          margin-top: 1rem;
          cursor: pointer;
        }
      }
      .share:hover{
        color: var(--lightBlue);
      }
    }
  }
}

footer {
  background: var(--lightBlue);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-top: 9rem;
  font-family: "Nunito Sans", sans-serif;
  a {
    padding-top: 1.5rem;
    cursor: unset;
    img {
      cursor: pointer;
      height: 30px;
      width: 30px;
    }
  }
  .about, .terms {
    display: flex;
    flex-direction: column;
    align-content: center;
    color: #FFFFFF;
    ul {
      display: flex;
      flex-direction: row;
      align-self: center;
      list-style-type: none;
      li {
        cursor: pointer;
        padding: 0.625rem;
        font-size: 13px;
      }
      li:hover {
        color: lightgrey;
      }
    }
    span {
      margin-top: 0.4rem;
    }
  }
  .about {
    padding-top: 1rem;
    text-transform: uppercase;
  }
  .terms {
    padding-bottom: .3rem;
  }
}

.confirm-page {
  footer {
    bottom: 0;
    width: 100%;
  }
}

.confirm-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  font-family: "Nunito Sans", sans-serif;
  margin-top: 80px;
  width: 100%;
  .code-input {
    align-self: center;
    width: 10rem;
    text-align: start;
    line-height: 24px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    border: none;
    border-bottom: 1px solid #000000;
    font-size: 24px;
    margin: 3rem 1rem 1rem;
    letter-spacing: 10px;
    padding: 5px;
  }
  input::placeholder {
    font-size: 32px;
    text-align: start;
    letter-spacing: 3px;
    color: rgba(0, 0, 0, 0.2);
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  h1 {
    margin-top: 50px;
    font-weight: bold;
    font-size: 44px;
    line-height: 60px;
    color: var(--lightBlue);
  }
  p {
    padding-bottom: 1rem;
    padding-top: 1rem;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: var(--darkBlue);
  }
  .wait {
    font-size: 16px;
    margin-top: 1rem;
    color: rgba(0, 0, 0, 0.74);
    span {
      font-size: 16px;
    }
    br {
      display: none;
    }
  }
  span {
    font-weight: 800;
    font-size: 20px;
    line-height: 30px;
    color: var(--darkBlue);
  }
  .resend-p {
    font-size: 14px;
    .resend {
      font-size: 14px;
      cursor: pointer;
      text-decoration: underline;
      color: var(--lightBlue);
    }
  }
  .confirm-btn {
    border: none;
    background-color: var(--lightBlue);
    text-transform: uppercase;
    text-align: center;
    align-self: center;
    border-radius: 2px;
    line-height: 42px;
    color: #FFFFFF;
    margin-bottom: 1rem;
    margin-top: 1rem;
    width: 200px;
    cursor: pointer;
  }
}

.header-info {
  text-align: center;
  margin-top: 3rem;
  height: 150px;
  position: relative;
  img {
    width: 200px;
  }
  h1 {
    margin-top: 1.2rem;
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
    color: var(--darkBlue);
  }
}
.icon-plus {
  cursor: pointer;
  color: #FFFFFF;
  border: 1px solid var(--darkBlue);
  border-radius: 15px;
  background: var(--darkBlue);
  width: 18px!important;
  height: 18px;
}
.input-date-wrap {
  display: flex;
  flex-direction: row;
  color: var(--accent);
  input {
    margin-right: 2rem;
    margin-bottom: 1rem;
    margin-left: 10px;
    width: 160px;
    padding: 3px;
    color: var(--darkBlue);
  }
  input:disabled {
    background: lightgrey!important;
  }
  hr {
    background: var(--accent);
    margin-right: 1rem;
    margin-left: -1rem;
    width: 5px;
    border: 1px solid var(--accent);
  }
}
.section-form-info {
  align-items: center;
  padding: 3rem 3rem;
  margin: 1rem 2rem;
  display: flex;
  flex-direction: column;
  .import-cv {
    border-radius: 2px;
    border: 1px solid rgba(40, 90, 132, 0.61);
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100px;
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    label {
      font-weight: bold;
      font-size: 14px;
      line-height: 22px;
      color: var(--darkBlue);
      text-align: center;
      width: 150px;
    }
    button {
      margin: 0 0 0 5rem;
    }
    a {
      text-decoration: none;
      color: white;
    }
    .linkedin-btn {
      .btn-custom {
        color: #FFFFFF;
        text-transform: uppercase;
        background: var(--lightBlue);
        line-height: 22px;
        border-radius: 2px;
        border: 1px solid rgba(40, 90, 132, 0.61);
        align-self: center;
        height: 40px;
        text-align: center;
        padding: .5rem;
        font-size: 0.9rem;
        font-weight: 200;
        font-family: "Nunito Sans", sans-serif;
        cursor: pointer;
        margin: 0 0 0 5rem;
        a {
          text-decoration: none;
          color: white;
        }
      }
    }
  }
  .input-wrap {
    display: flex;
    flex-direction: row;
    .text-input {
      width: 250px;
    }
  }
  .first-child {
    margin-right: 3rem;
  }
  .disabled {
    color: rgba(40, 90, 132, 0.75);
    input {
      background: rgba(175, 185, 197, 0.15)!important;
      color: rgba(40, 90, 132, 0.75);
      pointer-events: none;
    }
  }
  .text-input {
    width: 550px;
    padding: 0.5rem 0;
    display: flex;
    flex-direction: column;
    line-height: 22px;
    input {
      line-height: 33px;
      border-radius: 2px;
      border: 1px solid rgba(40, 90, 132, 0.61);
      padding: 0 10px;
      height: 45px;
      font-size: 1.0625rem;
      font-family: "Montserrat", sans-serif;
      outline: none;
    }
    input[type='dropdown'] {
      width: 100%;
      cursor: pointer;
    }
    input[type='date'] {
      width: 100%;
      cursor: pointer;
      background: white;
    }
    input[type='number'] {
      outline: none;
      width: 83%;
    }
    input:focus{
      outline: none;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    textarea {
      color: var(--darkBlue);
      border: 1px solid rgba(40, 90, 132, 0.61);
      box-sizing: border-box;
      border-radius: 2px;
      height: 80px;
      padding: 10px 10px;
      font-family: "Montserrat", sans-serif;
    }
    label {
      line-height: 22px;
      font-size: 1rem;
      text-align: start;
    }
    .custom-class {
      background: #FFFFFF !important;
      color: var(--darkBlue);
      span {
        padding: 10px;
        font-size: 16px;
        font-family: "Open Sans", sans-serif;
      }
    }
    .show-dropdown {
      display: block;
      border: 1px solid rgba(40, 90, 132, 0.61);
      box-sizing: border-box;
    }
    .error-required {
      font-size: 12px;
      color: #ff383a;
    }
    .skills-dropdown {
      height: 400px;
      overflow: scroll;
    }
    img {
      object-fit: cover;
      width: 25px;
      height: 25px;
    }
  }
  .chb-input {
    line-height: 19px;
    padding: 5px 5px 15px;
    vertical-align: center;
    label {
      font-family: "Open Sans", sans-serif;
      padding: 20px;
      font-size: 0.875rem;
    }
  }
  .photo-input {
    display: flex;
    flex-direction: column;
    .upload-wrap {
      display: flex;
      flex-direction: column;
      border: 1px solid rgba(40, 90, 132, 0.61);
      box-sizing: border-box;
      border-radius: 2px;
      height: 100%;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      text-align: center;
      align-items: center;
      padding: 1.6rem;
      font-family: 'Nunito Sans', sans-serif;
      .file-upload {
        cursor: pointer;
        color: var(--lightBlue);
      }
      .file-upload:hover {
        text-decoration: underline;
      }
      span {
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        font-family: 'Nunito Sans', sans-serif;
      }
      img {
        object-fit: cover;
        width: 100px;
        height: 100px;
      }
      .img-wrap {
        display: flex;
        flex-direction: row;
        .icon-close {
          width: 18px;
          height: 18px;
          margin-left: 8px;
          cursor: pointer;
          color: #FFFFFF;
          border: 1px solid var(--darkBlue);
          border-radius: 15px;
          background: var(--darkBlue);
        }
        .icon-close:hover {
          border: 1px solid var(--lightBlue);
          background: var(--lightBlue);
        }
      }
    }
  }
  .add-section-wrap {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1.5rem;
    div {
      flex: 1;
      padding-top: 1.6rem;
      text-align: end;
      label {
        cursor: pointer;
        margin-left: 10px;
      }
      .icon-plus {
        cursor: pointer;
        color: #FFFFFF;
        border: 1px solid var(--darkBlue);
        border-radius: 15px;
        background: var(--darkBlue);
        width: 18px;
        height: 18px;
      }
    }
    div:hover {
      color: var(--lightBlue);
      .icon-plus {
        border: 1px solid var(--lightBlue);
        background: var(--lightBlue);
      }
    }
  }
  .add-section {
    display: none;
  }
  h4 {
    padding-top: 1.5rem;
    font-weight: 800;
    font-size: 20px;
    line-height: 30px;
    color: var(--darkBlue);
    text-align: start;
  }
  span {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: var(--darkBlue);
    text-align: start !important;
  }
  .btn-custom {
    color: #FFFFFF;
    text-transform: uppercase;
    background: var(--lightBlue);
    line-height: 22px;
    border-radius: 2px;
    border: 1px solid rgba(40, 90, 132, 0.61);
    align-self: center;
    height: 40px;
    text-align: center;
    width: 250px;
    margin-left: 9rem;
    font-size: 0.9rem;
    font-weight: 200;
    font-family: "Nunito Sans", sans-serif;
    margin-top: 2rem;
    cursor: pointer;
    margin-bottom: 0.5rem;
  }
  .submit-btn {
    color: #FFFFFF;
    text-transform: uppercase;
    background: var(--lightBlue);
    border-radius: 2px;
    border: 1px solid rgba(40, 90, 132, 0.61);
    align-self: center;
    text-align: center;
    font-weight: 200;
    font-family: "Nunito Sans", sans-serif;
    cursor: pointer;
    width: 100%;
    height: 55px;
    font-size: 18px;
    line-height: 25px;
    margin: 2rem 0 0;
  }
  .dropdown-wrap {
    display: flex;
    flex-direction: row;
    .icon-arrow-down {
      width: 18px;
      height: 18px;
      margin-left: -30px;
      align-self: center;
      color: var(--darkBlue);
    }
    input::-webkit-calendar-picker-indicator {
      display: none;
    }
    .type-dropdown {
      width: 100%;
    }
  }
  .dropdown-input {
    position: relative;
    cursor: pointer;
    .dropdown-menu-custom {
      width: 100%;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      float: left;
      min-width: 10rem;
      padding: .5rem 0;
      margin: .125rem 0 0;
      font-size: 1rem;
      color: #212529;
      text-align: left;
      list-style: none;
      background-color: #fff;
      background-clip: padding-box;
      border-radius: 2px;
      border: 1px solid rgba(40, 90, 132, 0.61);
      button:focus {
         background: white;
         outline: none;
       }
      button:hover {
        background: rgba(239, 111, 83, 0.2);
      }
    }
    datalist {
      width: 100%;
    }
  }
}
.datalist {
  option:focus {
    background: white;
    outline: none;
  }
  option:hover {
    background: rgba(239, 111, 83, 0.2);
  }
}
.skills {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 550px;
  .skill {
    border: 1px solid #EF6F53;
    box-sizing: border-box;
    border-radius: 2px;
    color: var(--accent);
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 25px;
    padding: 10px;
    margin: 8px 15px 8px 0;
    .icon-close {
      margin-left: 15px;
      cursor: pointer;
    }
  }
}

.hamburger-menu {
  display: none;
}
.btn-wrap {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  margin-bottom: 1rem;
  .btn-custom-del {
    flex: 1;
    color: var(--lightBlue);
    border: 1px solid rgba(40, 90, 132, 0.61);
    box-sizing: border-box;
    border-radius: 2px;
    background: white;
    text-transform: uppercase;
    margin-right: 2rem;
    outline: none;
    cursor: pointer;
    font-size: .9rem;
  }
  .btn-custom {
    margin: 0;
  }
}
.section-wrap-jobs {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-right: 2rem;
  .sorting-wrap {
    display: flex;
    flex-direction: row;
    .btn-wrap {
      display: flex;
      flex-direction: row;
      flex: 1;
      .btn-accent {
        border: 1px solid var(--accent);
        box-sizing: border-box;
        border-radius: 2px;
        //height: 45px;
        padding: 10px 20px;
        width: auto;
        text-align: center;
        color: var(--accent);
        text-transform: uppercase;
        font-weight: bold;
        font-size: 16px;
        line-height: 25px;
        margin-right: 2rem;
        cursor: pointer;
      }
      .active {
        background: var(--accent);
        color: white;
      }
    }
    .dropdown-wrapper {
      display: flex;
      flex-direction: row;
      width: 190px;
      position: relative;
      .dropdown-btn {
        margin-left: auto;
        margin-top: 1rem;
        label {
          font-weight: 800;
          font-size: 18px;
          line-height: 30px;
          align-items: center;
          color: #191847;
          cursor: pointer;
          padding-left: 3rem;
        }
        .icon-arrow-down {
          cursor: pointer;
          margin-left: 10px;
        }
        .icon {
          margin-left: 1rem;
        }
      }
      .dropdown-menu-custom {
        background-color: white;
        margin-left: auto;
        padding: 0.5rem;
        border-radius: 2px;
        position: absolute;
        margin-top: 3.3rem;
        border: 1px solid var(--lightBlue);
        box-sizing: border-box;
        right: 0;
        button {
          color: var(--lightBlue);
          outline: none;
          cursor: pointer;
        }
        button:hover {
          background: rgba(239, 111, 83, 0.09);
        }
        button:focus {
          outline: none;
        }
      }
    }
  }
  .list-wrap {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    .item-wrapper {
      display: flex;
      flex-direction: column;
      background: #FFFFFF;
      border: 1px solid rgba(40, 90, 132, 0.61);
      box-sizing: border-box;
      box-shadow: 0 4px 15px rgba(40, 90, 132, 0.2);
      border-radius: 2px;
      margin: 1rem 0;
      padding: .2rem .2rem;
      color: var(--darkBlue);
      .item-wrap {
        display: flex;
        flex-direction: row;
        .item {
          display: flex;
          flex-direction: column;
          margin: 2rem;
          color: var(--darkBlue);
          width: 150px;
          label {
            font-weight: bold;
            text-transform: uppercase;
            padding-bottom: 0.4rem;
          }

          span {
            font-family: "Open Sans", sans-serif;
          }
        }
        .first {
          width: 190px;
        }
        .item-more-info {
          display: flex;
          flex-direction: row;
          margin: 3rem 1rem 2rem 2rem;
          span {
            vertical-align: center;
            text-transform: uppercase;
            cursor: pointer;
          }
          .icon {
            margin-top: 2px;
            margin-right: 20px;
            cursor: pointer;
          }
        }
        .more-info-wrap {
          display: flex;
          flex-direction: column;
        }
      }
      .bottom-border {
        border-bottom: 1px solid rgba(40, 90, 132, 0.61);
      }
      .more-info-wrapper {
        padding-bottom: 2rem;
        .sorting-wrap {
          margin: 1rem;
        }
        table {
          table-layout: fixed;
          border-collapse: collapse;
          width: 100%;
          margin-top: 2rem;
          padding: 1rem;
          td, th {
            border-bottom: 1px solid rgba(40, 90, 132, 0.61);
            padding: 0.5rem 1rem;
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            color: var(--darkBlue);
            width: 100%;
            text-align: center;
            span {
              display: none;
            }
          }
          tr {
            .icon {
              cursor: pointer;
            }
            svg {
              cursor: pointer;
            }
          }
        }
        .no-matching {
          padding: 2rem;
        }
      }
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: row;
  h6 {
    flex: 1;
  }
  .icon {
    margin-top: 1.2rem
  }
}

.expenses-overlay{
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,.5);
  z-index: 2000;
  .info{
    text-align: left;
  }
  .expenses-details{
    width: 40%;
    padding: 2rem;
    background: var(--c-bg);
    border: solid 1px var(--c-txt);
    display: flex;
    flex-direction: column;
    h1, h2 {
      margin: .5rem;
    }
    &.warning-details{
      h1, h2{
        text-align: center;
      }
      h2{
        color: var(--c-txt);
      }
    }
    .buttons-wrapper{
      display: flex;
      justify-content: space-between;
      margin: 1rem;
      > *{
        flex: 1;
        margin-right: 1rem;
        &:last-child{
          margin-right: 0;
        }
      }
      .btn-custom {
         color: #FFFFFF;
         text-transform: uppercase;
         background: var(--lightBlue);
         line-height: 22px;
         border-radius: 2px;
         border: 1px solid rgba(40, 90, 132, 0.61);
         align-self: center;
         height: 40px;
         text-align: center;
         width: 250px;
        font-size: 0.9rem;
         font-weight: 200;
         font-family: "Nunito Sans", sans-serif;
        cursor: pointer;
        margin: .5rem 2rem 0.5rem;
        a {
          text-decoration: none;
          color: white;
        }
      }
      .btn-custom-cancel {
        color: #FFFFFF;
        text-transform: uppercase;
        background: var(--lightBlue);
        line-height: 22px;
        border-radius: 2px;
        border: 1px solid rgba(40, 90, 132, 0.61);
        align-self: center;
        height: 40px;
        text-align: center;
        width: 250px;
        font-size: 0.9rem;
        font-weight: 200;
        font-family: "Nunito Sans", sans-serif;
        cursor: pointer;
        margin: .5rem 2rem 0.5rem;
      }
    }
  }
  .expense-row{
    display: grid;
    grid-template-columns: 4fr 1fr 1fr;
    &.total{
      p{
        font-weight: bold;
      }
    }
  }
  .expenses-header{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    button{
      background: none;
      outline: none;
      border: none;
      box-shadow: none;
    }
  }
  h1, h2{
    color: var(--c-primary);
    font-weight: bold;
  }
  p{
    color: var(--c-txt);
    margin: .5rem 0;
    text-align: right;
    &:first-child{
      text-align: left;
    }
  }
  h1{
    font-size: 20px;
    margin: 0;
    font-weight: bolder;
  }
  h2, p{
    font-size: 16px;
  }

}

img {
  object-fit: cover;
}

.spinner-wrapper-whiter {
  background: rgba(255, 255, 255, 1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
}

.spinner-wrapper{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.4);
  z-index: 99999999;
}

.spinner-wrapper > *{
  overflow: hidden!important;
}

.spinner {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  animation-name: spinner8;
  animation-duration: .7s;
  animation-iteration-count: infinite;
  animation-play-state: running;
}
.spinner:before {
  position: absolute;
  top: 0;
  left: 40%;
  display: block;
  content: " ";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color:
          rgba(30,30,30,.8);
  box-shadow:
          -14px  6px rgba(30,30,30,.7),
          -20px 20px rgba(30,30,30,.66),
          -14px 34px rgba(30,30,30,.6),
          0 40px rgba(30,30,30,.5),
          14px 34px rgba(30,30,30,.3),
          20px 20px rgba(30,30,30,.2),
          14px  6px rgba(30,30,30,.1);
}
@keyframes spinner8 {
  0%
  {
    transform: rotate(0deg);
  }
  12.4%
  {
    transform: rotate(0deg);
  }
  12.5%
  {
    transform: rotate(45deg);
  }
  24.9%
  {
    transform: rotate(45deg);
  }
  25%
  {
    transform: rotate(90deg);
  }
  37.4%
  {
    transform: rotate(90deg);
  }
  37.5%
  {
    transform: rotate(135deg);
  }
  49.9%
  {
    transform: rotate(135deg);
  }
  50%
  {
    transform: rotate(180deg);
  }
  67.4%
  {
    transform: rotate(180deg);
  }
  67.5%
  {
    transform: rotate(225deg);
  }
  74.9%
  {
    transform: rotate(225deg);
  }
  75%
  {
    transform: rotate(270deg);
  }
  87.4%
  {
    transform: rotate(270deg);
  }
  87.5%
  {
    transform: rotate(315deg);
  }
  99.7%
  {
    transform: rotate(315deg);
  }
}


@media (max-width: 450px) {
  main {
    overflow-x: hidden;
  }
  .section-login {
    background-size: 480px 250px;
    background-position: top;
    .section-content {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      img {
        width: 280px;
        height: 250px;
        margin: -3rem 2rem 2rem;
      }
      .registration-form {
        margin: 1.2rem;
        padding: 1.5rem;
        width: auto;
      }
    }
    .header {
      img {
        margin-top: 40px;
        margin-left: 60px;
      }
    }
  }
  .section-steps {
    flex-wrap: wrap;
    .title {
      font-size: 2rem;
    }
    .section-content {
      display: flex;
      flex-wrap: wrap;
      padding: 0.5rem 1rem;
      .step {
        padding: .5625rem;
        width: 48%;
        .first-img {
          margin-top: -.5rem;
        }
        img {
          width: 100%;
        }
        .step-text {
          padding-top: 0.4rem;
          h2 {
            font-size: 1.3rem;
          }
          p {
            font-size: .8rem;
            padding-top: 0.3rem;
          }
        }
      }
    }
  }
  .section-benefits {
    flex-wrap: wrap;
    .title {
      font-size: 2rem;
    }
    .section-content {
      display: flex;
      flex-wrap: wrap;
      background-position: top;
      background-size: contain;
      .second {
        margin-top: 0!important;
      }
      .benefit-wrap:first-child {
        margin-right: 1rem;
      }
      .benefit-wrap {
        width: fit-content;
        flex: 1;
        margin: 1rem 1rem -4rem;
        img {
          width: 300px;
          margin-top: 3rem;
        }
        .benefit-text {
          padding: 1rem;
          h2 {
            font-size: 1rem;
          }
          h1 {
            font-size: 1.2rem;
          }
          p {
            font-size: .8rem;
          }
        }
      }
    }
  }
  .second-div {
    margin-top: 5rem!important;
  }
  .human1 {
    width: 10rem;
  }
  .confirm-content {
    text-align: center;
    width: 100%;
    top: 7.5rem ;
    .code-input {
      text-align: start;
      width: 11rem;
      margin: 0.5rem ;
    }
    h1 {
      font-size: 24px;
      line-height: 30px;
    }
    p, span {
      font-size: 14px;
    }
    .wait {
      font-size: 14px;
      span {
        font-size: 14px;
      }
      br {
        display: block;
      }
    }
  }
  footer {
    li, p, span {
      font-size: 12px !important;
    }
  }
  .header-info {
    img {
      width: 150px;
    }
    h1 {
      font-size: 22px;
      line-height: 32px;
    }
  }
  .section-form-info {
    padding: 1rem;
    margin: 1rem;
    .import-cv {
      display: flex;
      flex-direction: column;
      height: 180px;
      padding: 1.5rem 1rem;
      .linkedin-btn {
        .btn-custom {
          margin: 0;
        }
      }
    }
    .input-wrap {
      display: flex;
      flex-direction: column;
      .text-input {
        width: 100%;
      }
    }
    .first-child {
      margin-right: 2rem;
    }
    .text-input {
      width: 100%;
      padding: 0.8rem 0;
      line-height: 18px;
      input {
        line-height: 28px;
        padding: 0 10px;
        height: 45px;
      }
      textarea {
        height: 80px;
        padding: 10px 10px;
      }
      label {
        line-height: 22px;
        font-size: 1rem;
      }
      input[type='number'] {
        width: 68%;
      }
    }
    .chb-input {
      padding: 5px 5px 15px;
      label {
        padding: 20px;
        font-size: 0.875rem;
      }
    }
    .add-section-wrap {
      display: flex;
      flex-direction: column;
      div {
        display: none;
      }
    }
    .add-section {
      display: block;
      margin-top: 1.5rem;
      text-align: center;
      label {
        margin-left: 10px;
      }
    }
    h4 {
      padding-top: 2rem;
      font-weight: 800;
      font-size: 1.2rem;
      line-height: 30px;
    }
    span {
      font-size: 14px;
      line-height: 19px;
    }
    .btn-custom {
      line-height: 22px;
      width: 250px;
      margin-left: 2.5rem;
      font-size: 0.9rem;
      margin-top: 1rem;
    }
    .submit-btn {
      width: 100%;
      height: 50px;
      font-size: 18px;
      line-height: 25px;
      margin: 1rem 0 0;
    }
    .skills {
      width: 100%;
      .skill {
        display: flex;
        flex-direction: row;
        font-size: 14px;
        line-height: 25px;
        padding: 10px;
        margin: 8px 15px 8px 0;
        .icon-close {
          margin-top: 5px;
          margin-left: 10px;
        }
      }
    }
    .photo-input{
      .upload-wrap{
        .file-upload{
          text-decoration: underline;
        }
      }
    }
  }
  .profile-wrap {
    .profile-content {
      width: 100%;
      padding: 1rem;
      margin-right: 0;
      main {
        margin-left: 0;
      }
      .section-wrap {
        display: block;
        width: 90%;
        .smaller-section:first-child {
          margin-right: 1rem;
        }
        .smaller-section {
          width: 100%;
        }
      }
      .info-section {
        padding: 2rem;
        margin: 1rem 1rem;
        display: block;
        width: 90%;
        .section-title {
          margin-bottom: 2rem;
        }
        h5 {
          margin-bottom: 1rem;
          font-size: 15px;
        }
        .info-item {
          font-size: 13px;
          p {
            padding-left: 25px;
            word-wrap: break-word;
          }
          input, textarea {
            margin-left: 35px;
            width: 40vw;
          }
          .text-input {
            .dropdown-wrap {
              input {
                margin-left: 35px;
                width: 40vw;
              }
            }
          }
          .dropdown-input {
            .dropdown-menu-custom {
              width: 40vw;
              margin-left: 35px;
              min-width: auto;
              overflow: scroll;
              .dropdown-item {
                font-size: 12px;
                padding-left: 10px;
                word-wrap: break-word;
              }
            }
          }
        }
        .editing-layout {
          .skills {
            margin-left: 35px;
            .skill {
              font-size: 12px;
              line-height: 25px;
              padding: 5px;
              word-break: break-word;
              margin: 8px 15px 8px 0;
              .icon-close {
                margin-left: 15px;
                cursor: pointer;
              }
            }
          }
        }
      }
      .section-above {
        height: 230px;
        margin: 0;
        padding: 0;
        .profile-img {
          background-image: url('../assets/images/profile-img-bkg.svg');
          background-repeat: no-repeat;
          background-size: 280px 220px;
          background-position: top;
          text-align: center;
          margin-top: -1.5rem;
          img {
            margin-top: 3.5rem;
            width: 80px;
            height: 80px;
            border-radius: 50px;
          }
          p {
            margin-bottom: 0.4rem;
            margin-top: 2rem;
            font-size: 12px;
            text-align: center;
            text-transform: uppercase;
            font-weight: bold;
          }
        }
        .share {
          text-align: center;
          float: initial;
          img {
            width: 15px;
            height: 15px;
            margin-right: .5rem;
          }
          label {
            font-size: 12px;
            margin-top: .5rem;
          }
        }
      }
    }
  }
  .profile-header {
    .human1 {
      width: 120px;
      margin-right: 1.5rem;
      margin-top: .5rem;
    }
    .dropdown-menu-header {
      display: none;
    }
  }
  header {
    display: flex;
    flex-direction: row;
  }
  .hamburger-menu {
    display: flex;
    flex: 1;
    svg {
      margin-left: 1.5rem;
      margin-top: 1.5rem;
    }
  }
  .mobile-menu {
    display: initial;
    position: fixed;
    left: 0;
    top: 0;
    transition: transform .3s .1s ease-in-out;
    width: 75%;
    height: 100%;
    transform: translateX(0%);
    padding-top: 80px;
    color: white;
    background: var(--lightBlue);
    border: 0.580713px solid rgba(40, 90, 132, 0.61);
    box-sizing: border-box;
    box-shadow: 0 2.32285px 8.71069px rgba(40, 90, 132, 0.5);
    visibility: hidden;
    z-index: 3;
    .profile-img {
      display: none;
    }
    .menu-wrap {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-left: 1rem;
      padding: 1rem;
      height: 100%;
      .menu-item {
        font-size: 12px;
        display: flex;
        flex-direction: row;
        padding: 1rem 0.3rem 0;
        svg {
          margin-right: 1rem;
          width: 30px;
          height: 30px;
          path {
            fill: white;
          }
        }
        label {
          font-size: 16px;
          line-height: 30px;
        }
      }
      .active {
        border-bottom: 0.580713px solid white;
        label {
          border: none;
          font-weight: bold;
          font-size: 14px;
        }
      }
      .logout {
        position: absolute;
        bottom: 10px;
      }
    }
  }
  .toggleMenu {
    visibility: initial;
  }
  .section-wrap {
    .sorting-wrap {
      flex-direction: column;
      .btn-wrap {
        text-align: center;
        align-items: center;
        .btn-accent {
          padding: 8px 5px;
          font-size: 10px;
          line-height: 15px;
          margin-right: 1rem;
          margin-top: 1rem;
          height: 35px;
          width: 120px;
          margin-left: 1rem;
        }
        .active {
          background: var(--accent);
          color: white;
        }
        .longer {
          width: 150px;
        }
      }
      .dropdown-wrapper {
        width: 100%;
        .dropdown-btn {
          width: 100%;
          label {
            font-size: 15px;
            padding-left: 5rem;
          }
        }
        .dropdown-menu-custom {
          font-size: .8rem;
        }
      }
    }
    .list-wrap {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      .item-wrap {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 1rem 0;
        padding: .2rem .2rem;
        font-size: 12px;
        .item {
          margin: 2rem;
          width: auto;
        }
        .first {
          //width: 100px;
        }
        .item-more-info {
          margin: 3rem 1rem 2rem 2rem;
        }
      }
    }
  }
  .header-info {
    margin-top: 0;
  }
  .btn-wrap {
    .btn-custom-del {
      padding: 5px 25px;
    }
    .btn-custom-del, .btn-custom{
      font-size: .69rem;
      font-weight: bold;
      height: 50px;
    }
    .btn-custom {
      margin: 0;
      width: 130px;
    }
  }
  .input-date-wrap {
    display: flex;
    flex-direction: column;
    color: var(--accent);
    margin-top: 1rem;
    .text-input {
      font-size: 13px;
      display: flex;
      flex-direction: row;
      width: 98%;
      input {
        margin-right: 0;
        margin-bottom: 1rem;
        margin-left: 35px;
        width: 40vw;
        padding: 3px;
        color: var(--darkBlue);
        background: white;
      }
      input:disabled {
        background: lightgrey!important;
      }
    }
    hr {
      display: none;
    }
  }
  .section-wrap-jobs {
    padding-right: 0;
    .sorting-wrap {
      flex-wrap: wrap;
      .btn-wrap {
        display: flex;
        flex-direction: row;
        text-align: center;
        .btn-accent {
          padding: 5px 15px;
          width: auto;
          font-size: 12px;
          font-weight: bold;
          margin-right: 0;
        }
        .btn-mobile {
          width: 138px;
        }
        .longer {
          right: 0;
          margin-right: 0;
          margin-left: auto;
        }
      }
      .dropdown-wrapper {
        width: 100%;
        .dropdown-btn {
          right: 0;
          font-size: 14px;
          margin-left: auto;
          label {
            font-size: 15px;
          }
        }
        .dropdown-menu-custom {
          padding: 5px;
          font-size: 14px;
          right: 0;
        }
      }
    }
    .list-wrap {
      .item-wrapper {
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        border: 1px solid rgba(40, 90, 132, 0.61);
        box-sizing: border-box;
        box-shadow: 0 4px 15px rgba(40, 90, 132, 0.2);
        border-radius: 2px;
        margin: 1rem 0;
        padding: .2rem .2rem;
        color: var(--darkBlue);
        .item-wrap {
          display: grid;
          grid-template-columns: 1fr 1fr;
          font-size: 13px;
          .item {
            display: flex;
            flex-direction: column;
            margin: 1.5rem;
            color: var(--darkBlue);
            width: auto;
            label {
              font-weight: bolder;
              font-size: 15px;
            }
          }
          .item-more-info {
            padding: 10px;
            width: 100%;
            border-radius: 2px;
            border: 1px solid var(--darkBlue);
            margin-top: 1rem;
            margin-left: 50%;
            text-align: center;
            display: inline;
            margin-right: 0;
          }
        }
        .more-info-wrapper {
          padding-bottom: 2rem;
          table {
            padding: .5rem;
            thead {
              display: none;
            }
            tr {
              display: grid;
              grid-template-columns: 1fr 1fr 1fr;
              border: 0.583908px solid rgba(40, 90, 132, 0.61);
              box-shadow: 0 2.33563px 8.75862px rgba(40, 90, 132, 0.2);
              margin: 1rem;
            }
            td, th {
              border-bottom: none;
              padding: 1rem .5rem;
              font-size: 11px;
              width: 100%;
              text-align: start;
              display: flex;
              flex-direction: column;
              span {
                text-transform: uppercase;
                display: initial;
                font-weight: bold;
                margin-bottom: 13px;
                font-size: 11px;
              }
              svg {
                align-self: center;
                margin-bottom: 13px;
              }
            }
            .mobile-padding {
              padding-left: 1.2rem;
            }
            .mobile-border {
              span {
                font-size: 9px;
                margin: 0;
              }
              padding: 5px;
              text-align: center;
              border: 0.15px solid rgba(40, 90, 132, 0.61);
            }
          }
        }
      }
    }
  }
  .container-filter {
    .container1 {
      width: 90%;
      .skills {
        width: 100%;
      }
    }
  }
}
